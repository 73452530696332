<template>
  <div>
    <div class='form-box rounded-lg white elevation-2'>
      <v-form ref='from'>
        <v-row align='center'>
          <v-col md='3'>
            <k-select
              v-model='from.moduleUuid'
              :items='list'
              input-width='250px'
              label='金融数字员工'
              label-width='7em'
              @change='columns = []'
            ></k-select>
          </v-col>
          <v-col md='3'>
            <k-select
              v-model='from.type'
              :items='listTwo'
              input-width='300px'
              label='记录类型'
            ></k-select>
          </v-col>
          <v-col md='3'>
            <k-tree-select
              v-model='query.organizationUUID'
              :items='items'
              input-width='300px'
              label='所属机构'
              placeholder='请选择所属机构'
            />
          </v-col>
          <v-col md='3'>
            <k-select
              v-model='query.itemsUuid'
              :clearable='true'
              :items='analysisItemsList'
              class='my-2'
              input-width='300px'
              label='分析项目'
            />
          </v-col>
          <v-col md='3'>
            <k-select
              v-model='dateRadio'
              :items='timeList'
              input-width='300px'
              itemText='name'
              label='日期'
              @change='timeChange'
            ></k-select>

          </v-col>
          <v-col md='3'>
            <k-date-range-select
              v-if='isCustom'
              v-model='from.dateRange'
              :disabled='!isCustom'
              style='margin-bottom: 0;margin-left: 20px'
              @change='dateRangeChange'
            ></k-date-range-select>
          </v-col>
          <v-col offset-md='4'>
            <k-warning-btn
              v-if="hasPermission(['organization.export'])"
              :loading='loading'
              class='btns'
              icon='mdi-magnify'
              text='查询'
              @click='search'
            />
            <k-primary-button
              v-if="hasPermission(['organization.export'])"
              :loading='loading'
              class='btns'
              icon='mdi-login-variant'
              text='导出'
              @click='exportsExcel'
            />
          </v-col>
        </v-row>

        <!--        <v-radio-group-->
        <!--          v-model='dateRadio'-->
        <!--          class='ml-4'-->
        <!--          row-->
        <!--         -->
        <!--        >-->
        <!--          <v-radio-->
        <!--            v-for='n in timeList'-->
        <!--            :key='n.value'-->
        <!--            :label='n.name'-->
        <!--            :value='n.value'-->
        <!--          ></v-radio>-->
        <!--        </v-radio-group>-->


        <!--        <v-radio-group-->
        <!--          v-model='isCustom'-->
        <!--          class='ml-4'-->
        <!--          style='margin-top: 0'-->
        <!--          row-->
        <!--          @change='isCustomChange'-->
        <!--        >-->
        <!--          <v-radio :value='true' label='自定义'></v-radio>-->
        <!--        </v-radio-group>-->


      </v-form>

    </div>
    <v-overlay :value='loading'>
      <v-progress-circular indeterminate size='64'></v-progress-circular>
      <!--      <view class="warning&#45;&#45;text">耐心等待...</view>-->
    </v-overlay>
    <k-crud-table :value="page" v-if='columns.length' :headers='columns' :items='tableData' :total='total'
                  style='margin-top: 30px'
                  @itemPerPage="itemPerPageChange"
                  @pageChanged='pageChanged'></k-crud-table>
  </div>
</template>

<script>
import {getList, exportExeclWarning, seachExeclWarning} from '@/api/reportManage/warningExport';
import Moment from 'moment';
import columns from '@/views/reportManage/warningExport/columns';
import KPrimaryButton from '@/components/koi/button/KPrimaryButton';

export default {
  name: 'WarningExport',
  components: {KPrimaryButton},
  data() {
    return {
      total: 0,
      itemPerPage:10,
      tableData: [],
      items: [],
      analysisItemsList: [
        {
          text: '卡把',
          value: 0,
        },
        {
          text: '交易',
          value: 1,
        },
        {
          text: '行领导查库',
          value: 2,
        },
        {
          text: '清机',
          value: 3,
        },
      ],
      loading: false,
      timeList: [
        {name: '自定义', value: 0},
        {name: '本月', value: 1, startOf: 'month'},
        {name: '本季度', value: 3, startOf: 'quarter'},
        {name: '本半年', value: 6, startOf: 'halfYear'},
        {name: '本年', value: 12, startOf: 'year'},
      ],
      dateRadio: null,
      isCustom: false,
      list: [],
      listTwo: [
        {
          text: '预警记录',
          value: 1,
        },
        {
          text: '违规记录',
          value: 2,
        },
        {
          text: '合规记录',
          value: 3,
        },
      ],
      from: {
        moduleUuid: null,
        type: 1,
        startTime: null,
        endTime: null,
        dateRange: [],
      },
      columns: [],
      query: {},
      page: 1,
    };
  },
  computed: {},
  mounted() {
    this.getSelect();
    this.getTree();
    this.getanalysisItemsList();
  },
  methods: {
    itemPerPageChange(e){
      this.itemPerPage = e
      this.pageChanged(1)
    },
    pageChanged(page) {
      this.page = page;
      this.getList()
    },
    getanalysisItemsList() {
      this.$api.nodeAnalysis.nodeAnalysis.getanalysisItemsList().then((res) => {
        this.analysisItemsList = res.data;
      });
    },
    getTree() {
      this.$api.organization.organization
        .getOrTree()
        .then(({code, data}) => {
          if (code === 200) {
            this.items = data;
          }
        });
    },
    setColumns() {
      // if (!this.from.moduleUuid) return;
      let key = 'cash';
      switch (this.from.moduleUuid) {
        case '5de5467f431345aa95a75d529df34491':
          key = 'cash';
          break;
        case '91e3c6657540421ab9beef7aea43c9b1':
          key = 'trans';
          break;
      }
      console.log(key);
      const arr = columns[key];
      this.columns = arr;
    },
    async getList() {
      this.loading = true;
      const start = this.from.startTime.substr(0, 11);
      const end = this.from.endTime.substr(0, 11);
      const name = `${
        (this.list.find((item) => item.value === this.from.moduleUuid) || {}).text
      }-${(this.listTwo.find((item) => item.value === this.from.type) || {}).text}`;
      const res = await seachExeclWarning({
        name: `${name}（${start} ${end}）`,
        query: {...this.from, ...this.query, pageNo: this.page, maxResults: 10},
      });
      this.tableData = res.data[0] ? res.data[0].records : [];
      this.total = res.data[0].total;
      this.loading = false;
    },
    search() {
      if (!this.$refs.from.validate()) return;
      this.setColumns();
      if (!this.from.startTime) {
        this.$alert.warning('请定义查询时间');
        return;
      }
      this.page = 1
      this.getList()

    },
    dateRangeChange(e) {
      if (e?.length) {
        this.from.startTime = `${e[0]} 00:00:00`;
        this.from.endTime = `${e[1]} 23:59:59`;
      }
    },
    isCustomChange() {
      this.dateRadio = null;
      this.from.startTime = '';
      this.from.endTime = '';
    },
    timeChange(e) {
      if (e === 0) {
        this.isCustom = true;
        this.isCustomChange();
        return;
      }
      this.isCustom = false;
      this.from.dateRange = [];
      const startOf = this.timeList.find(v => v.value == e).startOf

      let start, end;
      if (startOf === 'halfYear') {
        start = Moment().get('month') + 1 <= 6
          ? Moment().format(`${Moment().get('year')}-01-01 00:00:00`)
          : Moment().format(`${Moment().get('year')}-07-01 00:00:00`)
        end = Moment().format('YYYY-MM-DD HH:mm:ss')
      } else {
        end = Moment()
          .endOf('month')
          .minute(59)
          .hour(23)
          .second(59)
          .format('YYYY-MM-DD HH:mm:ss');
        start = Moment().startOf(startOf).format('YYYY-MM-DD HH:mm:ss');
      }
      this.from.startTime = start;
      this.from.endTime = end;
    },
    async exportsExcel() {
      if (this.$refs.from.validate()) {
        if (!this.from.startTime) {
          this.$alert.warning('请定义导出时间');
          return;
        }
        this.loading = true;
        const start = this.from.startTime.substr(0, 11);
        const end = this.from.endTime.substr(0, 11);
        const name = `${
          (this.list.find((item) => item.value === this.from.moduleUuid) || {}).text
        }-${(this.listTwo.find((item) => item.value === this.from.type) || {}).text}`;
        if (
          await exportExeclWarning({
            name: `${name}（${start} ${end}）`,
            query: {...this.from, ...this.query},
          })
        ) {
          this.loading = false;
        }
      }
    },
    getSelect() {
      getList().then(({data, code}) => {
        if (code === 200) {
          this.list = data;
          this.from.moduleUuid = data[0].value;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.d-flex {
  margin-bottom: 20px;
}

.form-box {
  padding: 0 10px;
}

.form-box, ::v-deep .v-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

::v-deep .v-data-table-header {
  tr {
    th {
      white-space: nowrap !important;
    }
  }
}

.btns {
  vertical-align: middle;
  margin-left: 20px;
}
</style>
